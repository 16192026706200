<template>
  <v-container grid-list-xl fluid>
    <header-title title="Reporte estadístico"></header-title>
    <v-row class="mb-6 text-center">
      <v-col cols="12" md="6" class="py-1">
        <v-select
          label="Convocatorias"
          v-model="convocatoria_id"
          :items="convocatorias"
          item-text="convocatoria"
          item-value="id"
          hide-details
          filled
          @change="getEstadisticas"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="dato.modalidades.length > 0">
      <v-row class="mb-6 text-center">
        <v-col
          cols="12"
          v-for="(item, index) in dato.modalidades"
          :key="index"
          sm="4"
          md="2"
        >
          <v-card :color="colorQuads[index]" dark class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="subtitle-1 mb-2">
                  {{ item.descripcion.toUpperCase() }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ item.total }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Postulantes inscritos
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-card color="#263238" dark class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="subtitle-1 mb-2">TOTAL</div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ total }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Postulantes inscritos
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" elevation="3">
            <highcharts class="chart" :options="chartModalidad"></highcharts>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <v-card class="mx-auto" elevation="3">
            <highcharts class="chart" :options="chartDepartamento"></highcharts>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <span class="red--text">No hay registros para visualizar.</span>
    </div>
  </v-container>
</template>

<script>
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import exportingInit from "highcharts/modules/exporting";

import HeaderTitle from "@/components/HeaderTitle";
import Service from "../utils/general";
import axios from "axios";
exportingInit(Highcharts);
highchartsMore(Highcharts);
export default {
  name: "admision-dashboard",
  components: {
    HeaderTitle,
  },
  data: () => ({
    message: "",
    loading: false,
    convocatoria_id: "",
    convocatorias: [],
    dato: {
      modalidades: [],
      departamentos: [],
    },
    chartModalidad: {},
    chartDepartamento: {},
    colorQuads: ["#689F38", "#EF6C00", "#00ACC1", "#BA68C8", "#039BE5"],
    user: null,
  }),
  computed: {
    total() {
      return this.dato.modalidades.reduce(
        (a, b) => a + (parseInt(b.total) || 0),
        0
      );
    },
  },
  created() {
    this.user = Service.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [1, 2,13];
    if (this.user && roles.includes(this.user.rid)) {
      this.getConvocatorias();
      //this.getEstadisticas();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    getConvocatorias() {
      axios
        .get(
          Service.getUrlAPI() + "admision/convocatorias",
          Service.getHeader()
        )
        .then((response) => {
          this.convocatorias = response.data;
          this.convocatoria_id =
            this.convocatorias[this.convocatorias.length - 1].id;
          this.getEstadisticas();
        })
        .catch(() => {
          console.error("Parametros no encontrado");
        });
    },
    getEstadisticas() {
      axios
        .get(
          Service.getUrlAPI() + "admision/dashboard/" + this.convocatoria_id,
          Service.getHeader()
        )
        .then((response) => {
          const colors = [
            "#9CCC65",
            "#F57C00",
            "#26C6DA",
            "#BA68C8",
            "#00B0FF",
          ];
          this.dato = response.data;
          let modalidad = {
            nombre: [],
            total: [],
          };
          const modalidades = response.data.modalidades;
          for (const key in modalidades) {
            modalidad.nombre.push(modalidades[key].descripcion);
            modalidad.total.push(modalidades[key].total * 1);
          }
          let departamento = {
            nombre: [],
            total: [],
          };
          const departamentos = response.data.departamentos;
          for (const key in departamentos) {
            departamento.nombre.push(departamentos[key].descripcion);
            departamento.total.push(departamentos[key].total * 1);
          }
          this.chartModalidad = {
            chart: {
              type: "column",
            },
            title: {
              text: "POSTULANTES POR MODALIDAD",
            },
            subtitle: {
              text: "Total de postulantes inscritos por modalidad",
            },
            xAxis: {
              categories: modalidad.nombre,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Total inscritos",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              enabled: true,
            },
            plotOptions: {
              column: {
                colorByPoint: true,
                dataLabels: {
                  enabled: true,
                  format: "{point.y}",
                },
              },
            },
            legend: {
              enabled: false,
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 0,
              backgroundColor: "#FFFFFF",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            colors: colors,
            series: [
              {
                name: "Total",
                data: modalidad.total,
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartDepartamento = {
            chart: {
              type: "column",
            },
            title: {
              text: "POSTULANTES POR DEPARTAMENTO",
            },
            subtitle: {
              text: "Total de postulantes inscritos por departamento",
            },
            xAxis: {
              categories: departamento.nombre,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Total inscritos",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              enabled: true,
            },
            plotOptions: {
              column: {
                colorByPoint: true,
                dataLabels: {
                  enabled: true,
                  format: "{point.y}",
                },
              },
            },
            legend: {
              enabled: false,
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 0,
              backgroundColor: "#FFFFFF",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Total",
                data: departamento.total,
                //color: "#4DD0E1",
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
        })
        .catch(() => {
          console.error("datos no encontrado");
        });
    },
  },
};
</script>
<style scoped>
.head-color-1 {
  background-color: #616161;
}
.head-color-1 th {
  color: white !important;
}
.head-color-2 {
  background-color: #e0e0e0;
}
</style>
